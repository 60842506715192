import { z } from 'zod';

export const SessionPeakSchema = z
  .object({
    timestamp: z.string(),
    powerPeak: z.number().nonnegative(),
  })
  .describe('A unit of data from the session');

export type SessionPeak = z.infer<typeof SessionPeakSchema>;
