import { useCallback } from 'react';

export const useExportToCsv = <TData extends object = object>() => {
  return useCallback((data: TData[], fileName: string) => {
    if (!data.length) return;
    const csv = csvMaker(data as [TData, ...TData[]]);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);
};

const csvMaker = <TData extends object>(data: [TData, ...TData[]]) => {
  const header = getHeaderRow(data);

  const csv = getDataRows(data).map(getParsedDataRow);
  return [header, ...csv].join('\n');
};

const valueParsing = (value: unknown) => {
  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
      return value;
    case 'boolean':
      return value;
    case 'object':
      if (value instanceof Date) return value.toISOString();
      return '';
    default:
      return '';
  }
};
function getParsedDataRow<TData extends object>(row: TData) {
  const values = Object.values(row);
  return values.map((v) => `"${valueParsing(v)}"`).join(',');
}

function getDataRows<TData extends object>(data: [TData, ...TData[]]): TData[] {
  return Array.isArray(data[0]) ? data.slice(1, -1) : data;
}

function getHeaderRow<TData extends object>(data: [TData, ...TData[]]): string {
  const header = Array.isArray(data[0]) ? data[0] : Object.keys(data[0]);
  return header.map((v) => (isParsable(v) ? `${v}` : '')).join(',');
}

const isParsable = (type: unknown): type is string | number | boolean => primitivesToParse.includes(typeof type);

const primitivesToParse = ['string', 'number', 'boolean'];
