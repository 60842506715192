import { Widget } from '@fjuel/components';
import { Header } from '@fjuel/components/WidgetHeader';
import { Typography } from '@mui/material';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { ApplicationContextProvider } from '../ApplicationContext';
import { RouterErrorBoundary } from '../RouterErrorBoundary';
import { queryClient } from '../configs/queryClient';
import { dashboardSiteDataLoader, sessionDataLoader, sitesDataLoader } from '../hooks';
import { MsalAppStateManager } from '../msal/MsalAppStateManager';
import { Layout } from './Layout';
import { ProtectedRoute } from './ProtectedRoute';
import { SignIn } from './SignIn';
import { SignOut } from './SignOut';
import { DeviceProvider } from './Device/DeviceProvider';
import { VesselEditProvider } from './vessel/VesselEditProvider';

export const router = createBrowserRouter([
  {
    path: 'auth',
    children: [
      { path: 'sign-in', element: <SignIn /> },
      { path: 'sign-out', element: <SignOut /> },
    ],
  },
  {
    path: '/',
    errorElement: <RouterErrorBoundary />,
    element: (
      <MsalAppStateManager>
        <ApplicationContextProvider>
          <DeviceProvider>
            <VesselEditProvider>
              <Layout>
                <Outlet />
              </Layout>
            </VesselEditProvider>
          </DeviceProvider>
        </ApplicationContextProvider>
      </MsalAppStateManager>
    ),
    children: [
      {
        index: true,
        lazy: async () => {
          const { SitePicker } = await import('../SitePicker');
          return {
            Component: SitePicker,
          };
        },
        loader: sitesDataLoader(queryClient),
      },
      {
        path: ':siteId',
        element: <Outlet />,
        children: [
          {
            index: true,
            lazy: async () => {
              const { DashboardPage } = await import('./dashboard/Dashboard.page');
              const { DashboardError } = await import('./dashboard/DashboardError');
              return {
                Component: DashboardPage,
                errorElement: <DashboardError />,
              };
            },
            loader: dashboardSiteDataLoader(queryClient),
          },
          {
            path: 'shore-power-units',
            lazy: async () => {
              const { ShorePowerUnitsOverviewPage } = await import('./shorePowerUnits/ShorePowerUnitsOverview.page');
              return { Component: ShorePowerUnitsOverviewPage };
            },
          },
          {
            path: 'vessel',
            element: <Outlet />,
            children: [
              {
                index: true,
                lazy: async () => {
                  const { VesselDashboardPage } = await import('./vessel/VesselDashboard.page');
                  return { Component: VesselDashboardPage };
                },
              },
              {
                path: ':id',
                lazy: async () => {
                  const { VesselPage } = await import('./vessel/Details');
                  return { Component: VesselPage };
                },
              },
            ],
          },
          {
            path: 'session',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { SessionIndex } = await import('./session/List');
                  return { Component: SessionIndex };
                },
              },
              {
                path: ':sessionId',
                lazy: async () => {
                  const { SessionDetailsPage } = await import('./session/Details');
                  return { Component: SessionDetailsPage };
                },
                loader: sessionDataLoader(queryClient),
              },
            ],
          },
          {
            path: 'price-models',
            lazy: async () => {
              const { SiteMarkupPage } = await import('./site/SiteMarkup.page');
              return { Component: SiteMarkupPage };
            },
          },
          {
            path: 'companies',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { CompanyPage } = await import('./company/List');
                  return { Component: CompanyPage };
                },
              },
              {
                path: ':companyId',
                lazy: async () => {
                  const { CompanyDetailsPage } = await import('./company/Details');
                  return { Component: CompanyDetailsPage };
                },
              },
            ],
          },
          {
            path: 'reports',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { ReportPage } = await import('./reports/Reports.page');
                  return { Component: ReportPage };
                },
              },
            ],
          },
          {
            path: 'port-call-planner',
            element: (
              <ProtectedRoute
                fallbackMessage={
                  <Widget>
                    <Header>Not included in subscription</Header>
                    <Typography>Upgrade subscription to access Port Call Planer</Typography>
                  </Widget>
                }
              >
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                lazy: async () => {
                  const { PortCallPlanerOverview } = await import('./portCallPlaner/PortCallPlanerOverview.page');
                  return { Component: PortCallPlanerOverview };
                },
              },
            ],
          },
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            index: true,
            lazy: async () => {
              const { InvoiceOverviewPage } = await import('./invoice/List');
              return { Component: InvoiceOverviewPage };
            },
          },
          {
            path: ':invoiceId',
            lazy: async () => {
              const { InvoiceDetailsPage } = await import('./invoice/InvoiceDetails.page');
              return { Component: InvoiceDetailsPage };
            },
          },
        ],
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute allowedRoles={{ siteAdmin: true }}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            lazy: async () => {
              const { UsersPage } = await import('./users/List');
              return { Component: UsersPage };
            },
          },
        ],
      },
    ],
  },
  {
    path: 'type-documentation',
    lazy: async () => {
      const { TypeDocumentationPage } = await import('../TypeDocumentation.page');
      return { Component: TypeDocumentationPage };
    },
  },
]);
