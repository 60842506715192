import React, { FC } from 'react';
import { ButtonProps, ListItem } from '@mui/material';
import { ButtonNavLink } from '@fjuel/components/SideMenu/ButtonNavLink.tsx';

interface ListItemNavLinkProps extends ButtonProps {
  to: `/${string}`;
  indent?: boolean;
  children?: React.ReactNode;
}

export const ListItemNavLink: FC<ListItemNavLinkProps> = ({ children, indent = false, ...props }) => {
  return (
    <ListItem disableGutters disablePadding sx={{ pl: indent ? 4 : undefined, my: 1 }}>
      <ButtonNavLink {...props}>{children}</ButtonNavLink>
    </ListItem>
  );
};
