import { z } from 'zod';
import { IMOSchema } from './IMOSchema';
import { ShorePowerUnitSchema } from './ShorePowerUnitSchema';
import { VesselSchema } from './VesselSchema';
import { Exportable } from '@fjuel/schemas/interfaces/Exportable.ts';

export const SessionSchema = z
  .object({
    id: z.string().uuid(),
    shorePowerUnitId: z.string().uuid(),
    shorePowerUnit: ShorePowerUnitSchema,
    vesselImo: IMOSchema.nullable(),
    vesselImoChecked: z.boolean(),
    vesselInArea: z.boolean(),
    voltage: z.number().nonnegative(),
    frequency: z.number().nonnegative(),
    amperage: z.number(),
    powerPeak: z.number().nonnegative(),
    unitPrice: z.number().nonnegative().default(0),
    totalPrice: z.number().nonnegative().default(0),
    totalEnergyConsumed: z.number(),
    estTonsCo2Saved: z.number(),
    averagePower: z.number().nonnegative(),
    timeStarted: z.coerce.date(),
    timeEnded: z.coerce.date().nullable(),
    invoiced: z.boolean(),
    siteCompanyId: z.string().uuid().nullable().default(null),
  })
  .and(
    z
      .object({
        vesselId: z.null(),
      })
      .or(
        z.object({
          vesselId: z.string().uuid(),
          vessel: VesselSchema,
        })
      )
  );

export type Session = Exportable & z.infer<typeof SessionSchema>;
