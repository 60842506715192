import { styled } from '@mui/material';
import { Popup } from 'react-map-gl';

export const StyledPopover = styled(Popup)(({ theme }) => ({
  '.mapboxgl-popup-content': {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    backgroundColor: '#0f1d45',
    color: 'white',
  },
  '.mapboxgl-popup-tip': {
    borderTopColor: '#0f1d45',
    borderBottomColor: '#0f1d45',
  },
  '& .mapboxgl-popup-close-button': {
    color: 'white',
  },
}));
