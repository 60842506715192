import { AuthError } from '@azure/msal-browser';
import { Box, Button, Typography } from '@mui/material';
import { SmileyXEyes } from '@phosphor-icons/react';
import { FC } from 'react';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ZodError } from 'zod';
import { CenteredContent } from './CenteredContent';
import FjuelLogo from './assets/fjuel.svg?react';

export const RouterErrorBoundary: FC = () => {
  const error = useRouteError();
  if (import.meta.env.DEV) console.error(error);

  return (
    <CenteredContent>
      <Box>
        <FjuelLogo width={'500px'} />

        {isRouteErrorResponse(error) && error.status === 404 ? (
          <>
            <Typography
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              variant="h1"
              gap={1}
              component={'h1'}
            >
              <SmileyXEyes /> <span>404</span>
            </Typography>
            <Typography fontSize={'large'}>
              {(import.meta.env.DEV && typeof error.data === 'string' && error.data) || 'Page not found'}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h3" component={'h1'}>
              Something went wrong
            </Typography>
            {getErrorMessage(error)}
          </>
        )}
      </Box>
    </CenteredContent>
  );
};

const getErrorMessage = (error: unknown) => {
  if (error instanceof ZodError) {
    console.error(error);
    return (
      <Box>
        <Typography>Validation error</Typography>
        {error.issues.map((issue) => (
          <Typography key={issue.path.join(',')}>
            {issue.path.join(',')}
            {issue.message}
          </Typography>
        ))}
      </Box>
    );
  }
  if (error instanceof AuthError) {
    return (
      <Box>
        <Typography>{error.message}</Typography>
        <Button component={Link} to="/auth/sign-in" variant="outlined">
          Go to login
        </Button>
      </Box>
    );
  }

  if (error instanceof Error) {
    return error.message;
  }
  console.error(error);
  return 'Unknown error';
};
