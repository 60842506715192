import { z } from 'zod';

export const KPIResponseSchema = z.object({
  earnings: z.number().nonnegative(),
  consumption: z.number().int(),
  emissionSaved: z.number(),
  sessionCount: z.number().int().nonnegative(),
  currentConsumption: z.number().nonnegative(),
});

export type KPI = z.infer<typeof KPIResponseSchema>;
