import { FixedPriceMarkup, DynamicPriceMarkup } from '@fjuel/schemas';
import { z } from 'zod';
import { CableHandlingCostSchema } from './CableHandlingCostSchema';

export const PriceMarkupUpdateRequestSchema = z
  .discriminatedUnion('priceType', [DynamicPriceMarkup, FixedPriceMarkup])
  .and(
    z.object({
      id: z.string().uuid(),
      cableHandlingKr: CableHandlingCostSchema,
    })
  );

export type PriceMarkupUpdateRequest = z.infer<typeof PriceMarkupUpdateRequestSchema>;
