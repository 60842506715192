import { formatDuration } from 'date-fns';

export function dateTimeFormatter(date: Date) {
  return Intl.DateTimeFormat('en-GB', {
    timeStyle: 'medium',
    dateStyle: 'medium',
  }).format(date);
}

export function dateTimeShortFormatter(date: Date) {
  return Intl.DateTimeFormat('nb-NO', {
    timeStyle: 'short',
    dateStyle: 'short',
  }).format(date);
}

export function dateFormatterMedium(date: Date) {
  return Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
  }).format(date);
}

export function timeFormatShort(date: Date) {
  return Intl.DateTimeFormat('nb-NO', {
    timeStyle: 'short',
  }).format(date);
}

export const formatDurationShort = (duration: Duration) => {
  const formatted = formatDuration(duration, {
    format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
  });
  return formatted
    .replace('years', 'y')
    .replace('year', 'y')
    .replace('months', 'm')
    .replace('month', 'm')
    .replace('weeks', 'w')
    .replace('week', 'w')
    .replace('days', 'd')
    .replace('day', 'd')
    .replace('hours', 'h')
    .replace('hour', 'h')
    .replace('minutes', 'm')
    .replace('minute', 'm')
    .replace(/(\d)\s/g, '$1'); // remove whitespace behind numbers
};

export const durationToMilliseconds = (duration: Duration) => {
  const { years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0 } = duration;
  return (
    years * 31536000000 + months * 2592000000 + weeks * 604800000 + days * 86400000 + hours * 3600000 + minutes * 60000
  );
};
