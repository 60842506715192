import { FC } from 'react';
import { ChartLineUp, PresentationChart } from '@phosphor-icons/react';
import { CollapsibleMenu, MenuItem } from '@fjuel/components/SideMenu/CollapsibleMenu.tsx';
import { useApplicationContext } from '@fjuel/ApplicationContext.tsx';

interface BusinessPerformanceProps {
  onMenuClose?: () => void;
}

export const BusinessPerformance: FC<BusinessPerformanceProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();

  const items: Array<MenuItem> = [
    { label: 'Reports', to: `/${siteId}/reports`, icon: <ChartLineUp weight="duotone" />, disabled: !siteId },
  ];

  return (
    <>
      {siteId === 'acf387d5-7f24-470b-8615-0358023308d7' && (
        <CollapsibleMenu
          id={'businessPerformance'}
          title={'Business Performance'}
          icon={<PresentationChart />}
          items={items}
          onMenuClose={onMenuClose}
        />
      )}
    </>
  );
};
