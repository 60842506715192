import { z } from 'zod';
import { PermissionSchema } from './PermissionSchema';

export const UserWithPermissionSchema = z.object({
  createdOn: z.coerce.date(),
  createdBy: z.string().uuid().nullable(),
  modifiedOn: z.coerce.date().nullable(),
  modifiedBy: z.string().uuid().nullable(),
  hidden: z.boolean(),
  isActive: z.boolean(),
  id: z.string().uuid(),
  givenName: z.string().nullable(),
  surname: z.string().nullable(),
  email: z.string().email().nullable(),
  b2CObjectId: z.string().uuid(),
  company: z.string().uuid(),
  perms: PermissionSchema,
});
export type UserWithPermission = z.infer<typeof UserWithPermissionSchema>;
