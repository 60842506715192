import { MainContentContainer, Widget } from '@fjuel/components';
import { useMe } from '@fjuel/hooks';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute: FC<
  PropsWithChildren<{ allowedRoles?: { siteAdmin?: boolean }; fallbackMessage?: ReactNode }>
> = ({ children, allowedRoles = { siteAdmin: false }, fallbackMessage }) => {
  const { data: user, isPending, isError } = useMe();

  if (isPending) {
    return <MessageBox message="Loading..." />;
  }
  if (isError) {
    return <MessageBox message="Something went wrong while fetching user" />;
  }

  if (user.isSuperAdmin) {
    return children;
  }

  if (user.isSiteAdmin && allowedRoles.siteAdmin) {
    return children;
  }

  if (fallbackMessage) {
    return fallbackMessage;
  }

  return <Navigate to="/" replace />;
};

const MessageBox: FC<{ message: string }> = ({ message }) => {
  return (
    <MainContentContainer>
      <Widget>
        <Typography>{message}</Typography>
      </Widget>
    </MainContentContainer>
  );
};
