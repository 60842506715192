import { fjuelApi } from '@fjuel/configs/api';
import { msalInstance } from '@fjuel/msal/msalInstance';
import { RoleSchema } from '@fjuel/schemas';
import { QueryClientLoaderFunction } from '@fjuel/types/QueryClientLoaderFunction';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

export const useRoles = createQuery({
  primaryKey: 'role',
  queryFn: async () => z.array(RoleSchema).parse(await fjuelApi.get(`role`).json()),
});

export const roleDataLoader: QueryClientLoaderFunction = (queryClient) => async () => {
  const instance = await msalInstance;
  const activeAccount = instance.getActiveAccount();
  if (!activeAccount) return null;
  return queryClient.ensureQueryData({
    queryKey: useRoles.getKey(),
    queryFn: useRoles.queryFn,
  });
};
