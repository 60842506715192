/* eslint-disable react-refresh/only-export-components */
import { createContext, FC, PropsWithChildren, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';
const applicationContext = createContext<ReturnType<typeof useApplicationContextManager> | null>(null);

applicationContext.displayName = 'ApplicationContext';

function useApplicationContextManager() {
  const { siteId } = useParams();
  const navigate = useNavigate();

  const [prevSiteId, setSiteId] = useLocalStorage<string | undefined>('siteId', undefined);

  useEffect(() => {
    if (!siteId) {
      return;
    }

    const result = z.string().uuid().safeParse(siteId);

    if (result.success) {
      setSiteId(siteId);
      return;
    }
  }, [setSiteId, siteId]);

  useEffect(() => {
    if (!prevSiteId) {
      navigate('/');
      return;
    }
  }, [navigate, prevSiteId]);

  return { siteId: prevSiteId, setSiteId };
}

export const ApplicationContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <applicationContext.Provider value={useApplicationContextManager()}>{children}</applicationContext.Provider>
);

export const useApplicationContext = () => {
  const context = useContext(applicationContext);
  if (!context) {
    throw new Error('useApplicationContext must be used within a applicationContextProvider');
  }

  return context;
};
