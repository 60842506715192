import { z } from 'zod';

export const SessionDataSchema = z
  .object({
    timestamp: z.string(),
    totalEnergyConsumed: z.number(),
    energyConsumed: z.number(),
    activePowerTotal: z.number().nonnegative(),
    powerPeak: z.number().nonnegative(),
    spotPrice: z.number().nullable().default(null),
  })
  .describe('A unit of data from the session');

export type SessionData = z.infer<typeof SessionDataSchema>;
