import { z } from 'zod';

const isValidIMO = (v: string) => {
  const digits = v.replace('IMO', '').trim();
  const lastDigit = digits.slice(-1);
  const rest = digits.slice(0, -1);
  const sum = rest
    .split('')
    .map((digit) => parseInt(digit, 10))
    .reduce((acc, curr, index) => acc + curr * (7 - index), 0);
  const checkDigit = (sum % 10).toString();
  return lastDigit === checkDigit;
};

export const IMOSchema = z
  .string()
  .transform((v) => v.replace(' ', '') as `${number}`)
  .describe('IMO number');
export type IMO = z.infer<typeof IMOSchema>;

export const IMONumberStrict = z.coerce
  .string()
  .trim()
  .length(7)
  .refine(isValidIMO, { message: 'Invalid IMO' })
  .transform((v) => v.replace(' ', '') as `${number}`);

export type IMONumber = z.infer<typeof IMONumberStrict>;
