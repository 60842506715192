import { Table } from '@tanstack/react-table';
import { Button } from '@mui/material';
import { getExportedValues } from '@fjuel/components/ExportToCsv/getExportedValues.ts';
import { useDevice } from '@fjuel/pages/Device/DeviceContext.ts';
import { Exportable } from '@fjuel/schemas/interfaces/Exportable.ts';
import { useExportToCsv } from '@fjuel/hooks';
import { dateTimeShortFormatter } from '@fjuel/utils';

export interface ExportToCsvProps<T extends Exportable> {
  table: Table<T>;
  prefix: string;
}

export const ExportToCsv = <T extends Exportable>({ table, prefix }: ExportToCsvProps<T>) => {
  const { isPhone, isTablet } = useDevice();
  const height = isPhone || isTablet ? '40px' : '56px';
  const exportToCsv = useExportToCsv();

  const handleExportClick = () => {
    const fileName = `${prefix}-${dateTimeShortFormatter(new Date())}`;
    exportToCsv(getExportedValues(table), fileName);
  };

  return (
    <Button
      size="large"
      variant="outlined"
      sx={{ whiteSpace: 'nowrap', flex: 'none', height }}
      onClick={handleExportClick}
    >
      Export to CSV
    </Button>
  );
};
