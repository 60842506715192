import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msal.config.js';

export const msalInstance = PublicClientApplication.createPublicClientApplication(msalConfig).then(async (instance) => {
  const accounts = instance.getAllAccounts();

  if (accounts.length > 0 && accounts[0]) {
    instance.setActiveAccount(accounts[0]);
  }

  instance.addEventCallback((event: EventMessage) => {
    if (!(event.eventType === EventType.LOGIN_SUCCESS && event.payload)) {
      return;
    }
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    instance.setActiveAccount(account);
  });

  return instance;
});
