import React, { createContext, FC, ReactNode, useState } from 'react';

interface MenuContextType {
  openMenuItems: {
    shorePowerConnect: boolean;
    pricingAndPaymentSolution: boolean;
    businessPerformance: boolean;
    portCallPlaner: boolean;
  };
  setOpenMenuItems: React.Dispatch<
    React.SetStateAction<{
      shorePowerConnect: boolean;
      pricingAndPaymentSolution: boolean;
      businessPerformance: boolean;
      portCallPlaner: boolean;
    }>
  >;
}

export const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [openMenuItems, setOpenMenuItems] = useState({
    pricingAndPaymentSolution: false,
    shorePowerConnect: true,
    businessPerformance: false,
    portCallPlaner: false,
  });

  return <MenuContext.Provider value={{ openMenuItems, setOpenMenuItems }}>{children}</MenuContext.Provider>;
};
