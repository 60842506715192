import { Layer } from 'react-map-gl';
import { FC } from 'react';

export const BuildingsLayer: FC = () => {
  return (
    <Layer
      id="3d-buildings"
      source="composite"
      source-layer="building"
      filter={['==', 'extrude', 'true']}
      type="fill-extrusion"
      paint={{
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
        'fill-extrusion-opacity': 0.6,
      }}
    />
  );
};
