import { FC } from 'react';
import { ActiveSession } from '@fjuel/schemas';
import { Box, Typography } from '@mui/material';
import { CapacityGraph } from '@fjuel/components/graphs/CapacityGraph.tsx';

interface BottomLeftProps {
  session: ActiveSession;
}

export const BottomLeft: FC<BottomLeftProps> = ({ session }) => {
  return (
    <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={1} justifyContent={'start'}>
      <Typography fontWeight={300} fontSize={12}>
        Active power (kW)
      </Typography>
      <CapacityGraph
        currentLoad={session.activePower ?? 0}
        maxLoad={Math.round(session.powerPeak) ?? 0}
        style={{ width: '100px', height: '100px' }}
      />
    </Box>
  );
};
