import { fjuelApi } from '@fjuel/configs/api';
import { msalInstance } from '@fjuel/msal/msalInstance';
import { SiteSchema } from '@fjuel/schemas';
import { QueryClientLoaderFunction } from '@fjuel/types/QueryClientLoaderFunction';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

export const useSites = createQuery({
  primaryKey: 'sites',
  queryFn: async () => z.array(SiteSchema).parse(await fjuelApi.get(`site`).json()),
});

export const sitesDataLoader: QueryClientLoaderFunction = (queryClient) => async () => {
  const instance = await msalInstance;
  const activeAccount = instance.getActiveAccount();
  if (!activeAccount) return null;
  return queryClient.ensureQueryData({
    queryKey: useSites.getKey(),
    queryFn: useSites.queryFn,
  });
};
