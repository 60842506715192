import { z } from 'zod';

export const EmbedToken = z.object({
  token: z.string(),
  tokenId: z.string(),
  expiration: z.string(),
});

export const ReportSchema = z
  .object({
    type: z.string(),
    embedUrl: z.string(),
    accessToken: z.string(),
    reportId: z.string(),
    id: z.string(),
    embedToken: EmbedToken,
    minutesToExpiration: z.number(),
    isEffectiveIdentityRolesRequired: z.any(),
    isEffectiveIdentityRequired: z.any(),
    enableRLS: z.boolean(),
    username: z.string(),
    roles: z.string(),
    errorMessage: z.string(),
  })
  .describe('A unit of data from the session');

export type Report = z.infer<typeof ReportSchema>;
