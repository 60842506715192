import { coerceStringWithSuffix } from '@fjuel/utils';
import { z } from 'zod';

const pre = (v: unknown) => {
  let coercedValue;

  if (typeof v === 'number') {
    coercedValue = `${v}V`;
  } else if (typeof v === 'string' && v === '') {
    coercedValue = coerceStringWithSuffix('V')(0);
  } else if (v == null) {
    coercedValue = coerceStringWithSuffix('V')(0);
  } else {
    coercedValue = coerceStringWithSuffix('V')(v);
  }

  // Fall back to '0V' if the coerced value is not recognized
  if (!['0V', '400V', '440V', '690V'].includes(coercedValue)) {
    return '0V';
  }

  return coercedValue;
};

export const VoltageSchema = z.preprocess(pre, z.enum(['0V', '400V', '440V', '690V']));
