import { z } from 'zod';
import { CompanyDtoSchema } from '../CompanyDtoSchema';
import { IPCSchema } from '../ipc/IPCSchema';
import { EffectMarkupsSchema } from './EffectMarkupsSchema';

export const BasePriceMarkupSchema = z.object({
  id: z.string().uuid().nullable().default(null).describe('Unique identifier of the price markup'),
  ipcId: z.string().uuid().nullable().default(null).describe('Unique identifier of the IPC'),
  ipc: IPCSchema,
  siteCompanyId: z.string().uuid().nullable().default(null).describe('Unique identifier of the company'),
  siteCompany: CompanyDtoSchema.omit({ siteId: true, id: true }).nullable().default(null),
  cableHandlingKr: z.coerce.number().int().nonnegative().describe('Static cost of handling the cable connection'),
  effectMarkups: EffectMarkupsSchema,
});

export const DynamicPriceMarkup = z.object({
  priceType: z.literal('DYNAMIC'),
  krPerKwh: z.coerce.number().positive().describe('Dynamic markup per kWh in NOK'),
});
export type DynamicPriceMarkup = z.infer<typeof DynamicPriceMarkup>;

export const FixedPriceMarkup = z.object({
  priceType: z.literal('FIXED'),
  fixedKrPerKwh: z.coerce.number().positive().describe('Fixed markup per kWh in NOK'),
});
export type FixedPriceMarkup = z.infer<typeof FixedPriceMarkup>;

// export const PercentagePriceMarkup = z.object({
//   priceType: z.literal('DYNAMIC'),
//   spotPricePercentage: z.coerce.number().positive().describe('Percentage of the spot price added to the price'),
// })

// export type PercentagePriceMarkup = z.infer<typeof PercentagePriceMarkup>

export const PriceMarkupSchema = z.discriminatedUnion('priceType', [
  DynamicPriceMarkup.merge(BasePriceMarkupSchema),
  // PercentagePriceMarkup.merge(BasePriceMarkupSchema),
  FixedPriceMarkup.merge(BasePriceMarkupSchema),
]);

export type PriceMarkup = z.infer<typeof PriceMarkupSchema>;
