import { useCreateVessel, useSessionDetails, useSetSessionVessel, useUpdateVessel, useVessels } from '@fjuel/hooks';
import { Vessel, IMONumber } from '@fjuel/schemas';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { IContext, VesselEditContext } from './useVesselEditContext.ts';

const useChangeVesselHandler = (): IContext => {
  const {
    value: isSelectingSessionVessel,
    setFalse: disableIsSelectingSessionVessel,
    setTrue: enableIsSelectingSessionVessel,
  } = useBoolean(false);

  const [vesselToEdit, setVesselToEdit] = useState<Partial<EditableVessel>>();
  const { sessionId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutateAsync: createVessel } = useCreateVessel();

  const { mutateAsync: setSessionVessel } = useSetSessionVessel();

  function isEmptyGuid(guid: string) {
    return guid == '000000-0000-0000-0000-000000000000';
  }

  const { mutateAsync: updateVessel } = useUpdateVessel({
    onSuccess: () => enqueueSnackbar('Vessel updated', { variant: 'success' }),
    onError: () =>
      enqueueSnackbar('Something went wrong while updating vessel', {
        variant: 'error',
      }),
    onSettled: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: useVessels.getKey() }),
        queryClient.invalidateQueries({ queryKey: useSessionDetails.getKey({ sessionId }) }),
      ]);
      setVesselToEdit(undefined);
    },
  });

  const handleSessionVesselPicked = async (vessel: Vessel) => {
    if (vessel?.imo != null && isEmptyGuid(vessel.id)) {
      await handleVesselSelect(vessel.imo);
    } else {
      await handleSessionVesselChange(vessel);
    }
  };

  const handleVesselSelect = async (imo: IMONumber) => {
    if (!sessionId) {
      enqueueSnackbar('Session ID not found', { variant: 'error' });
      return;
    }

    enqueueSnackbar('Adding vessel to system', { variant: 'info' });

    try {
      const newVessel = await createVessel(imo);
      await queryClient.invalidateQueries({ queryKey: useVessels.getKey() });
      await handleSessionVesselChange(newVessel);
      disableIsSelectingSessionVessel();
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(`Failed to add vessel to system: ${error.message}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to add vessel to system', { variant: 'error' });
      }
      console.error('Error adding vessel:', error);
    }
  };

  const handleSessionVesselChange = async (vessel: Vessel) => {
    if (!sessionId) throw new Error('Session ID not found');
    await setSessionVessel({ sessionId, vessel });
    await queryClient.invalidateQueries({ queryKey: useSessionDetails.getKey({ sessionId }) });
    enqueueSnackbar(`Changed session vessel to ${vessel.name}`, {
      variant: 'success',
    });
    disableIsSelectingSessionVessel();
  };

  return {
    vesselToEdit,
    setVesselToEdit,
    updateVessel,
    isSelectingSessionVessel,
    disableIsSelectingSessionVessel,
    enableIsSelectingSessionVessel,
    onVesselSelect: handleVesselSelect,
    onSessionVesselChange: handleSessionVesselPicked,
  };
};

export const VesselEditProvider = ({ children }: { children: ReactNode }) => {
  const {
    vesselToEdit,
    setVesselToEdit,
    updateVessel,
    isSelectingSessionVessel,
    disableIsSelectingSessionVessel,
    enableIsSelectingSessionVessel,
    onVesselSelect: handleVesselSelect,
    onSessionVesselChange: handleSessionVesselPicked,
  } = useChangeVesselHandler();
  return (
    <VesselEditContext.Provider
      value={{
        vesselToEdit,
        setVesselToEdit,
        updateVessel,
        isSelectingSessionVessel,
        disableIsSelectingSessionVessel,
        enableIsSelectingSessionVessel,
        onVesselSelect: handleVesselSelect,
        onSessionVesselChange: handleSessionVesselPicked,
      }}
    >
      {children}
    </VesselEditContext.Provider>
  );
};

export type EditableVessel = Pick<Vessel, 'imo' | 'frequencyHz' | 'voltageV' | 'name'>;
