import { fjuelApi } from '@fjuel/configs/api';
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined';
import { Session, SessionSchema } from '@fjuel/schemas';
import { minutesToMilliseconds } from 'date-fns';
import { createQuery } from 'react-query-kit';

export const useSessionDetails = createQuery<Session, { sessionId: string | undefined }>({
  primaryKey: 'session',
  queryFn: async ({ queryKey: [url, { sessionId }] }) => {
    return SessionSchema.parse(await fjuelApi.get(`${url}/${sessionId}`).json());
  },
  staleTime: minutesToMilliseconds(30),
  gcTime: minutesToMilliseconds(60),
  use: [disableIfVariablesUndefined(['sessionId'])],
});
