import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateFunction } from 'react-router-dom';

export class ReactRouterMsalNavigationClient extends NavigationClient {
  constructor(private navigate: NavigateFunction) {
    super();
    this.navigate = navigate; // Passed in from useNavigate hook provided by react-router-dom;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url: string, options: NavigationOptions) {
    // url will be absolute, parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}
