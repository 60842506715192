import { FC } from 'react';
import { Button, Pagination, PaginationItem, Typography } from '@mui/material';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

interface PaginatorProps {
  count: number;
  page: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
  rowsPerPage: number;
}

const buttonSx = {
  '@container (max-width: 800px)': {
    fontSize: 0,
    '.MuiButton-endIcon, .MuiButton-startIcon': { mx: 0 },
    minWidth: 0,
  },
};

const getPages = ({ rowsPerPage, count }: { rowsPerPage: number; count: number }) => {
  return Math.ceil(count / rowsPerPage);
};

export const Paginator: FC<PaginatorProps> = ({ count, page, onPageChange, siblingCount, rowsPerPage }) => {
  return (
    <Pagination
      sx={{ justifySelf: 'center' }}
      siblingCount={siblingCount}
      color="primary"
      boundaryCount={2}
      page={page + 1}
      onChange={(_e, page) => onPageChange(page)}
      count={getPages({ count, rowsPerPage })}
      renderItem={(item) => {
        if (item.type === 'start-ellipsis') return <Typography>...</Typography>;
        if (item.type === 'end-ellipsis') return <Typography>...</Typography>;
        if (item.type === 'page') return <PaginationItem {...item} onClick={() => onPageChange(item.page! - 1)} />;
        if (item.type === 'previous') {
          return (
            <Button
              disabled={item.disabled}
              onClick={() => onPageChange(page - 1)}
              startIcon={<CaretLeft />}
              aria-label="Previous page"
              sx={buttonSx}
            >
              Previous
            </Button>
          );
        }
        if (item.type === 'next') {
          return (
            <Button
              aria-label="Next page"
              sx={buttonSx}
              disabled={item.disabled}
              onClick={() => onPageChange(page + 1)}
              endIcon={<CaretRight />}
            >
              Next
            </Button>
          );
        }
      }}
    />
  );
};
