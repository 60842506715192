import { FC } from 'react';
import { Anchor, FlagPennant, Lock } from '@phosphor-icons/react';
import { CollapsibleMenu, MenuItem } from '@fjuel/components/SideMenu/CollapsibleMenu.tsx';
import { useMe } from '@fjuel/hooks';
import { useApplicationContext } from '@fjuel/ApplicationContext.tsx';

interface PortCallPlannerProps {
  onMenuClose?: () => void;
}

export const PortCallPlanner: FC<PortCallPlannerProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();
  const { data: me } = useMe();

  const items: Array<MenuItem> = me?.isSuperAdmin
    ? [
        {
          label: 'Overview',
          to: `/${siteId}/port-call-planner`,
          icon: <FlagPennant weight="duotone" />,
          disabled: !siteId,
        },
      ]
    : [{ label: 'Upgrade', to: `/`, icon: <Lock />, disabled: true }];

  return (
    <>
      <CollapsibleMenu
        id={'portCallPlaner'}
        title={'Port Call Planner'}
        icon={<Anchor />}
        items={items}
        onMenuClose={onMenuClose}
      />
    </>
  );
};
