import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import FjuelLogo from '../../assets/fjuel.svg?react';
import { useApplicationContext } from '@fjuel/ApplicationContext.tsx';

interface LogoBoxProps {
  onMenuClose?: () => void;
}

export const LogoBox: FC<LogoBoxProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();

  return (
    <Box
      to={siteId ? `/${siteId}` : '/'}
      component={Link}
      sx={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '&:-webkit-any-link ': { color: 'inherit' },
        '&:visited': { color: 'inherit' },
      }}
      onClick={onMenuClose}
    >
      <FjuelLogo />
    </Box>
  );
};
