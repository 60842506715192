import { Configuration, SilentRequest } from '@azure/msal-browser';
import { env } from '@fjuel/configs/env';

export const msalConfig: Configuration = {
  cache: {
    cacheLocation: 'localStorage',
  },
  auth: {
    clientId: env.VITE_MSAL_CLIENT_ID,
    redirectUri: '/auth/sign-in',
    postLogoutRedirectUri: '/auth/sign-out',
    authority: 'https://fjuelas.b2clogin.com/FjuelAS.onmicrosoft.com/B2C_1_SignIn_Basic',
    knownAuthorities: ['https://fjuelas.b2clogin.com/FjuelAS.onmicrosoft.com/B2C_1_SignIn_Basic'],
  },
};

export const loginRequest: {
  scopes: SilentRequest['scopes'];
} = {
  scopes: ['https://fjuelas.onmicrosoft.com/1fe6a641-5aad-405a-a023-ec7c4e2b313d/Api.Access'],
};
