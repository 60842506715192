import { FC } from 'react';
import { LayerProps } from './LayerProps.ts';
import { useSiteIpcs } from '@fjuel/hooks';
import { Layer, Source } from 'react-map-gl';
import { layout, paint, SourceLayers } from '@fjuel/components/SiteMap/Constants.ts';
import { IPC } from '@fjuel/schemas';

export const IpcsLayer: FC<LayerProps> = ({ siteId, showLabels }) => {
  const { data: ipcs } = useSiteIpcs({ variables: { siteId }, select: (ipcs) => ipcs.map(ipcToFeature) });

  return (
    <Source id={SourceLayers.ipcs} data={{ type: 'FeatureCollection', features: ipcs ?? [] }} type="geojson">
      <Layer
        id={SourceLayers.ipcs}
        type="symbol"
        paint={paint}
        layout={{ ...layout({ showLabels }), 'icon-size': 0.7, 'icon-image': 'IPC' }}
      />
    </Source>
  );
};

const ipcToFeature = (ipc: IPC): GeoJSON.Feature<GeoJSON.Point, IPC> => ({
  type: 'Feature',
  properties: { ...ipc },
  geometry: {
    type: 'Point',
    coordinates: [ipc.location.lng, ipc.location.lat],
  },
});
