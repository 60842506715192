import { fjuelApi } from '@fjuel/configs/api';
import { ShorePowerUnitDetailsResponse, ShorePowerUnitDetailsResponseSchema } from '@fjuel/schemas';
import { endOfToday, minutesToMilliseconds, subMonths } from 'date-fns';
import { createQuery } from 'react-query-kit';

export const useShorePowerUnitDetails = createQuery<
  ShorePowerUnitDetailsResponse,
  { ShorePowerUnitId: string; to: string; from: string }
>({
  primaryKey: 'shore_power_unit-details',
  retry: false,
  staleTime: minutesToMilliseconds(60),
  gcTime: minutesToMilliseconds(120),
  queryFn: async ({ queryKey: [_primaryKey, { ShorePowerUnitId, from, to }] }) => {
    return ShorePowerUnitDetailsResponseSchema.parse(
      await fjuelApi
        .get(`shorepowerunit/${ShorePowerUnitId}/data`, {
          searchParams: { from, to },
          timeout: minutesToMilliseconds(2),
        })
        .json()
    );
  },
});

export const defaultToFrom = {
  from: subMonths(endOfToday(), 1).toISOString(),
  to: endOfToday().toISOString(),
};
