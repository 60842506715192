import { fjuelApi } from '@fjuel/configs/api';
import { SessionData, SessionDataSchema } from '@fjuel/schemas';
import { minutesToMilliseconds } from 'date-fns';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSessionData = createQuery<SessionData[], GetSessionQueryParams>({
  primaryKey: 'sessiondata',
  queryFn: ({ queryKey: [_, variables] }) => getSessionData(variables),
  staleTime: minutesToMilliseconds(30),
  gcTime: minutesToMilliseconds(60),
  use: [disableIfVariablesUndefined('sessionId')],
});

type GetSessionQueryParams = {
  sessionId: string | undefined;
  from?: Date;
  to?: Date;
};

export const getSessionData = async ({ sessionId, from, to }: GetSessionQueryParams): Promise<SessionData[]> => {
  if (!sessionId) return [];
  const searchParams = new URLSearchParams();
  if (from) {
    searchParams.append('from', from.toJSON());
  }
  if (to) {
    searchParams.append('to', to.toJSON());
  }

  return z.array(SessionDataSchema).parse(
    await fjuelApi
      .get(`session/${sessionId}/data`, {
        searchParams,
      })
      .json()
  );
};
