import { Middleware, QueryHook } from 'react-query-kit';

export function enableIf<TData, TVariables extends object>(
  predicateFn: (variables: TVariables) => boolean
): Middleware<QueryHook<TData, TVariables>> {
  return (useQueryNext) => {
    return ({ variables, ...options }) => {
      return useQueryNext({
        ...options,
        variables,
        enabled: variables && predicateFn(variables),
      });
    };
  };
}
