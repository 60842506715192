import { FC } from 'react';
import { LayerProps } from './LayerProps.ts';
import { useActiveSessions } from '@fjuel/hooks';
import { Layer, Source } from 'react-map-gl';
import { ActiveShorePowerUnitToFeature } from '@fjuel/shorePowerUnitToFeature.ts';
import { layout, paint, SourceLayers } from '@fjuel/components/SiteMap/Constants.ts';

export const SpusLayer: FC<LayerProps> = ({ siteId, showLabels }) => {
  const { data: activeSessions } = useActiveSessions({ variables: { siteId } });
  const shorePowerUnits = activeSessions?.sessionData
    .map((v) => v.shorePowerUnits.map(ActiveShorePowerUnitToFeature))
    .flat();

  return (
    <>
      <Source id="ShorePowerUnits" data={{ type: 'FeatureCollection', features: shorePowerUnits ?? [] }} type="geojson">
        <Layer
          id="area"
          type="fill-extrusion"
          paint={{
            'fill-extrusion-color': ['get', 'color'],
            'fill-extrusion-opacity': 0.8,
            'fill-extrusion-height': ['get', 'height'],
            'fill-extrusion-base': ['get', 'base_height'],
          }}
        />
      </Source>
      <Source
        id="shorePowerUnitsNotCharging"
        data={{
          type: 'FeatureCollection',
          features: shorePowerUnits?.filter((spu) => spu.properties.session === null) ?? [],
        }}
        type="geojson"
      >
        <Layer
          id={SourceLayers.shorePowerUnitsNotCharging}
          type="symbol"
          paint={paint}
          layout={{ ...layout({ showLabels }), 'icon-size': 0.2, 'icon-image': 'shore-power-unit-not-charging' }}
        />
      </Source>
      <Source
        id="shorePowerUnits"
        data={{
          type: 'FeatureCollection',
          features: shorePowerUnits?.filter((spu) => spu.properties.session !== null) ?? [],
        }}
        type="geojson"
      >
        <Layer
          id={SourceLayers.shorePowerUnits}
          type="symbol"
          paint={paint}
          layout={{ ...layout({ showLabels }), 'icon-size': 0.2, 'icon-image': 'shore-power-unit' }}
        />
      </Source>
    </>
  );
};
