import { z } from 'zod';
import { VesselSchema } from './VesselSchema';

export const VesselByImoSchema = VesselSchema.pick({
  imo: true,
  name: true,
}).transform((vessel) => ({
  ...vessel,
  imo: vessel.imo?.replace('IMO', ''),
}));
export type VesselByImo = z.infer<typeof VesselByImoSchema>;
