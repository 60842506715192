import { Typography, TypographyProps } from '@mui/material';
import { FC, PropsWithChildren, ReactElement } from 'react';

export const TextWithIcon: FC<
  PropsWithChildren<
    { title?: string; endIcon?: ReactElement; startIcon?: ReactElement; gap?: TypographyProps['gap'] } & TypographyProps
  >
> = ({ title, endIcon: EndIcon, startIcon: StartIcon, children, gap = 1, ...rest }) => (
  <Typography display={'flex'} alignItems={'center'} gap={gap} {...rest} title={title}>
    {StartIcon}
    <span>{children}</span>
    {EndIcon}
  </Typography>
);
