import { FC } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';

const itemsPerPage = [10, 25, 50, 100];

interface RowsPerPageSelectorProps {
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const RowsPerPageSelector: FC<RowsPerPageSelectorProps> = ({ rowsPerPage, onRowsPerPageChange }) => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={1} justifySelf={'end'}>
      <Typography fontWeight={600}>Rows per page:</Typography>
      <Select
        size="small"
        autoWidth
        aria-label="Select rows per page"
        value={rowsPerPage ?? ''}
        onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
      >
        {itemsPerPage.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
