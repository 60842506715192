import { Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface WidgetHeaderProps extends TypographyProps {
  children: ReactNode;
}

export const WidgetHeader: FC<WidgetHeaderProps> = ({ children, ...rest }) => {
  return (
    <Typography
      variant="h4"
      component={rest.component ?? 'h1'}
      color={'primary'}
      sx={{ 'text-wrap': 'balance' }}
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  );
};
