import { FC } from 'react';
import { ActiveSession } from '@fjuel/schemas';
import { Box } from '@mui/material';
import { InlineStat } from '@fjuel/components/InlineStat.tsx';

interface BottomRightProps {
  session: ActiveSession;
}

export const BottomRight: FC<BottomRightProps> = ({ session }) => {
  return (
    <Box>
      <InlineStat
        label={'Voltage'}
        value={session.voltage.toLocaleString('no-NB') ?? 0}
        unit={'V'}
        showNotAvailableWhenZero={true}
      />
      <InlineStat
        label={'Current'}
        value={session.amperage.toLocaleString('no-NB') ?? 0}
        unit={'A'}
        showNotAvailableWhenZero={true}
      />
      <InlineStat
        label={'Frequency'}
        value={session.frequency.toLocaleString('no-NB') ?? 0}
        unit={'Hz'}
        showNotAvailableWhenZero={true}
      />
      <InlineStat
        label={'Peak active power'}
        value={Math.round(session.powerPeak).toLocaleString('no-NB') ?? 0}
        unit={'kW'}
      />
    </Box>
  );
};
