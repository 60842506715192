import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

interface ButtonNavLinkProps extends ButtonProps {
  to: string;
  children?: React.ReactNode;
}

export const ButtonNavLink: FC<ButtonNavLinkProps> = ({ children, ...props }) => {
  const location = useLocation();
  const isMatch = location.pathname === props.to;
  const sx = isMatch ? {} : { color: 'inherit', borderColor: 'inherit' };
  return (
    <Button
      component={NavLink}
      variant={isMatch ? 'contained' : 'text'}
      {...props}
      sx={{ ...props.sx, ...sx, px: 2, textTransform: 'none' }}
    >
      {children}
    </Button>
  );
};
