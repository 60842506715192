import { FC } from 'react';
import { ActiveSession } from '@fjuel/schemas';
import { Box, Typography } from '@mui/material';
import { VesselTypeIconWithTooltip } from './VesselTypeIconWithTooltip.tsx';

interface TopLeftProps {
  session: ActiveSession;
}

export const TopLeft: FC<TopLeftProps> = ({ session }) => {
  return (
    <Box display="flex" gap={1} alignItems={'flex-start'}>
      <Box display="flex" gap={1} alignItems={'center'}>
        <VesselTypeIconWithTooltip vesselType={session.vessel == null ? 0 : session.vessel.shipType} size={28} />
        <Box>
          <Typography fontWeight={300} fontSize={12}>
            IMO {session.vesselImo}
          </Typography>
          <Typography fontWeight={600} fontSize={14}>
            {session.vessel?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
