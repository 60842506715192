import { z } from 'zod';
import { RoleSchema } from '..';

export const PermissionSchema = z.object({
  sites: z.array(z.string().uuid()).nullable(),
  roles: z.array(RoleSchema).nullable(),
  vessels: z.array(z.string().uuid()).nullable(),
  isSuperAdmin: z.coerce.boolean(),
});
export type Permission = z.infer<typeof PermissionSchema>;
