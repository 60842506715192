import { SvgIcon } from '@mui/material';
import { FC } from 'react';

export const PriceAndPay: FC = () => {
  return (
    <SvgIcon>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64648 7.19736C4.64648 8.86516 5.30901 10.4646 6.48832 11.644C7.66763 12.8233 9.26712 13.4858 10.9349 13.4858C12.6027 13.4858 14.2022 12.8233 15.3815 11.644C16.5608 10.4646 17.2233 8.86516 17.2233 7.19736C17.2233 5.52957 16.5608 3.93008 15.3815 2.75077C14.2022 1.57146 12.6027 0.908936 10.9349 0.908936C9.26712 0.908936 7.66763 1.57146 6.48832 2.75077C5.30901 3.93008 4.64648 5.52957 4.64648 7.19736Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M4.64648 7.19736C4.64648 8.86516 5.30901 10.4646 6.48832 11.644C7.66763 12.8233 9.26712 13.4858 10.9349 13.4858C12.6027 13.4858 14.2022 12.8233 15.3815 11.644C16.5608 10.4646 17.2233 8.86516 17.2233 7.19736C17.2233 5.52957 16.5608 3.93008 15.3815 2.75077C14.2022 1.57146 12.6027 0.908936 10.9349 0.908936C9.26712 0.908936 7.66763 1.57146 6.48832 2.75077C5.30901 3.93008 4.64648 5.52957 4.64648 7.19736Z"
          stroke="white"
          strokeWidth="1.13766"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6077 5.45143C12.5175 5.19578 12.3502 4.9744 12.1289 4.81781C11.9077 4.66122 11.6432 4.57714 11.3721 4.57715H10.3564C10.0689 4.57867 9.79205 4.686 9.57864 4.87865C9.36523 5.0713 9.23022 5.33578 9.19938 5.62162C9.16854 5.90746 9.24402 6.19465 9.41143 6.42839C9.57883 6.66213 9.82644 6.82605 10.107 6.88886L11.6524 7.22701C11.9678 7.29665 12.2464 7.48022 12.4348 7.74254C12.6232 8.00486 12.7082 8.32747 12.6734 8.64857C12.6387 8.96966 12.4867 9.26666 12.2466 9.48263C12.0065 9.69861 11.6951 9.81839 11.3721 9.81901H10.4978C10.2268 9.81883 9.96246 9.73467 9.7412 9.57811C9.51994 9.42154 9.35263 9.20027 9.26228 8.94472"
          stroke="white"
          strokeWidth="1.13766"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9349 4.57705V3.26562"
          stroke="white"
          strokeWidth="1.13766"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9349 11.1291V9.81763"
          stroke="white"
          strokeWidth="1.13766"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.54669 7.88647C0.911353 9.05697 0.664116 10.399 0.840626 11.719C1.01714 13.0391 1.60828 14.269 2.52882 15.2314C3.44937 16.1939 4.65176 16.8392 5.96266 17.0742C7.27356 17.3093 8.62524 17.122 9.82284 16.5393"
          stroke="white"
          strokeWidth="1.13766"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
