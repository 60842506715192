import { fjuelApi } from '@fjuel/configs/api';
import { Invoices, InvoicesSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useInvoices = createQuery<Invoices, { siteId: string | undefined }>({
  primaryKey: 'invoices',
  queryFn: async ({ queryKey: [, { siteId }] }) => {
    const data = await fjuelApi.get(`invoice/site/${siteId}`).json();
    return InvoicesSchema.parse(data);
  },
  use: [disableIfVariablesUndefined(['siteId'])],
});
