import { HarbourVessel, Vessel } from '@fjuel/schemas';

export const vesselToFeature = <TVessel extends Vessel | HarbourVessel>(
  vessel: TVessel
): GeoJSON.Feature<GeoJSON.Point, TVessel> => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [vessel.location?.lng ?? 0, vessel.location?.lat ?? 0],
    },
    properties: vessel,
  };
};
