import { fjuelApi } from '@fjuel/configs/api';
import { User } from '@fjuel/schemas';
import { createMutation } from 'react-query-kit';

export const useUpsertUser = createMutation({
  mutationFn: (user: User) => {
    const method = user.id ? 'put' : 'post';
    const url = user.id ? `User/${user.id}` : 'User';
    return fjuelApi[method](url, { json: user }).json();
  },
});
