import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';

import { CssBaseline, ThemeProvider } from '@mui/material';
import '@total-typescript/ts-reset';
import { ErrorBoundary } from 'react-error-boundary';
import { queryClient } from './configs/queryClient';
import { theme } from './configs/theme';
import { msalInstance } from './msal/msalInstance';
// Account selection logic is app dependent. Adjust as needed for different use cases.
void msalInstance.then((instance) => {
  let appReady: Promise<void | ServiceWorkerRegistration> = Promise.resolve();

  if (import.meta.env.MODE === 'development') {
    // https://mswjs.io/docs/recipes/deferred-mounting
    appReady = import('./mocks/browser.ts').then(({ worker }) =>
      worker.start({
        onUnhandledRequest(req, print) {
          if (req.url === 'fjuel-api-dev.azurewebsites.net') {
            print.warning();
          }
        },
      })
    );
  }
  void appReady.then(() => {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary
            fallback={
              <div>
                <h1>Something went wrong</h1>
              </div>
            }
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App pca={instance} />
            </ThemeProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </React.StrictMode>
    );
  });
});
