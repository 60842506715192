import { z } from 'zod';
import { LocationSchema } from './LocationSchema';
import { ShorePowerUnitPlugSchema } from './ShorePowerUnitPlugSchema';

export const ShorePowerUnitSchema = z.object({
  id: z.string().uuid(),
  ipcId: z.string().uuid(),
  ipcName: z.string().nullable(),
  siteId: z.string().uuid(),
  externalId: z.string().nullable(),
  name: z.string().nullable(),
  location: LocationSchema.nullable(),
  status: z.preprocess(
    (v) => (v ? String(v).toLowerCase() : v),
    z.enum(['available', 'occupied', 'out-of-order', 'active', 'unknown']).nullable().default('unknown')
  ),
  peakEffect: z.number().default(0),
  alarms: z.array(z.string()).default([]),
  plugs: z.array(ShorePowerUnitPlugSchema).nullable().default([]),
});

export type ShorePowerUnit = z.infer<typeof ShorePowerUnitSchema>;
