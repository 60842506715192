import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import accessibility from 'highcharts/modules/accessibility';
import solidGauge from 'highcharts/modules/solid-gauge';
import { CSSProperties, FC, useMemo } from 'react';

more(Highcharts);
solidGauge(Highcharts);
accessibility(Highcharts);

const widthHeightDefault = '200px';
export const CapacityGraph: FC<{
  currentLoad: number;
  maxLoad?: number;
  style?: CSSProperties;
  dataLabelSuffix?: string;
}> = ({ currentLoad, maxLoad = 100, style, dataLabelSuffix = '' }) => {
  const options: Highcharts.Options = useMemo(() => {
    return {
      title: {
        text: undefined,
      },
      pane: {
        center: ['50%', '50%'],
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            innerRadius: '80%',
            outerRadius: '100%',

            backgroundColor: 'hsla(224, 36%, 28%, 1)',
            borderWidth: 0,
          },
        ],
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'solidgauge',
        backgroundColor: 'transparent',
      },
      tooltip: { enabled: false },
      yAxis: {
        min: 0,
        max: maxLoad,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        labels: {
          enabled: false,
          style: {
            color: 'inherit',
            fontSize: '2rem',
          },
          y: 35,
        },
      },
      plotOptions: {
        solidgauge: {
          stickyTracking: false,
          dataLabels: {
            position: 'center',
            enabled: true,
            y: -15,
            style: {
              fontSize: '1rem',
              color: 'inherit',
            },
            borderWidth: 0,
            zIndex: 100,
            format: `{y:,.0f} ${maxLoad !== undefined && `/ ${maxLoad}`} ${dataLabelSuffix}`,
            useHTML: false,
          },
        },
      },
      series: [getUsageSeries(currentLoad)],
    };
  }, [currentLoad, dataLabelSuffix, maxLoad]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { width: widthHeightDefault, height: widthHeightDefault, ...style } }}
    />
  );
};

const getUsageSeries = (currentLoad: number): Highcharts.SeriesSolidgaugeOptions => {
  return {
    type: 'solidgauge',
    name: 'Usage',
    dataLabels: {
      style: {
        color: '#fff',
      },
    },
    data: [
      {
        name: 'Usage',
        y: currentLoad,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'hsla(221, 75%, 54%, 1)'],
            [1, 'hsla(153, 96%, 67%, 1)'],
          ],
        },
        radius: '100%',
        innerRadius: '80%',
      },
    ],
  };
};
