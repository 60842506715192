import { IncludedFilter } from '@fjuel/components';
import { IconButton, Popover } from '@mui/material';
import { Funnel } from '@phosphor-icons/react';
import { Column, RowData } from '@tanstack/react-table';
import { useState } from 'react';

export const FilterMenu = <TData extends RowData>({
  column,
  options,
}: {
  column: Column<TData>;
  options: string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} color="inherit" size="small">
        <Funnel weight="light" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <IncludedFilter column={column} options={options} />
      </Popover>
    </>
  );
};
