import React, { useContext } from 'react';
import { IMONumber, UpdateVesselFormData, Vessel } from '@fjuel/schemas';
import { EditableVessel } from '@fjuel/pages/vessel/VesselEditProvider/VesselEditProvider.tsx';

export interface IContext {
  vesselToEdit?: Partial<EditableVessel>;
  setVesselToEdit: (vessel: Partial<EditableVessel> | undefined) => void;
  updateVessel: (args: { id: string; vessel: UpdateVesselFormData }) => Promise<Vessel>;
  isSelectingSessionVessel: boolean;
  disableIsSelectingSessionVessel: () => void;
  enableIsSelectingSessionVessel: () => void;
  onVesselSelect: (imo: IMONumber) => Promise<void>;
  onSessionVesselChange: (vessel: Vessel) => Promise<void>;
}

export const VesselEditContext = React.createContext<IContext | undefined>(undefined);

export const useVesselEditContext = () => {
  const context = useContext(VesselEditContext);
  if (context === undefined) {
    throw new Error('useVesselEditContext must be used within a VesselEditProvider');
  }
  return context;
};
