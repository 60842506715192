import { FC } from 'react';
import { TextWithIcon } from '@fjuel/components';
import { GlowingCircle } from '@fjuel/components/icons/GlowingCircle.tsx';

interface ChargingIndicatorProps {
  isCharging: boolean;
}

export const ChargingIndicator: FC<ChargingIndicatorProps> = ({ isCharging }) => (
  <>
    {isCharging ? (
      <TextWithIcon gap={0.5} color="primary" startIcon={<GlowingCircle color="lightGreen" />}>
        Charging
      </TextWithIcon>
    ) : (
      <TextWithIcon gap={0.5} color="secondary" startIcon={<GlowingCircle color="pink" />}>
        Not charging
      </TextWithIcon>
    )}
  </>
);
