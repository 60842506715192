import { IconButton, Tooltip, Typography } from '@mui/material';
import { Copy } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

export const CopyToClipboard: FC<{ text: string | number }> = ({ text }) => {
  const [, copy] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const onCopy = async () => {
    await copy(`${text}`);
    enqueueSnackbar(
      <Typography>
        <Typography component={'strong'} fontWeight={600}>
          {text}
        </Typography>{' '}
        copied to clipboard
      </Typography>,
      { variant: 'success' }
    );
  };

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton size="small" onClick={onCopy}>
        <Copy />
      </IconButton>
    </Tooltip>
  );
};
