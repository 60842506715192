import { FC } from 'react';
import { PriceAndPay } from '@fjuel/components/icons/PriceAndPay.tsx';
import { Receipt, Tag } from '@phosphor-icons/react';
import { CollapsibleMenu, MenuItem } from '@fjuel/components/SideMenu/CollapsibleMenu.tsx';
import { useApplicationContext } from '@fjuel/ApplicationContext.tsx';

interface PricingAndPaymentProps {
  onMenuClose?: () => void;
}

export const PricingAndPayment: FC<PricingAndPaymentProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();

  const items: Array<MenuItem> = [
    { label: 'Invoices', to: `/invoice`, icon: <Receipt weight="duotone" />, disabled: !siteId },
    { label: 'Price models', to: `/${siteId}/price-models`, icon: <Tag weight="duotone" />, disabled: !siteId },
  ];

  return (
    <>
      <CollapsibleMenu
        id={'pricingAndPaymentSolution'}
        title={'Pricing and Payment'}
        icon={<PriceAndPay />}
        items={items}
        onMenuClose={onMenuClose}
      />
    </>
  );
};
