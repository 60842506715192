import { Box } from '@mui/material';
import { FC } from 'react';
import { useDevice } from '@fjuel/pages/Device/DeviceContext.ts';
import { TwoLinedPagination } from '@fjuel/components/table/TablePagination/TwoLinedPagination.tsx';
import { OneLinedPagination } from './OneLinedPagination';

interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onPageChange: (page: number) => void;
  siblingCount?: number;
}

export const TablePagination: FC<TablePaginationProps> = ({
  count,
  page,
  rowsPerPage,
  onRowsPerPageChange,
  onPageChange,
  siblingCount,
}) => {
  const { start, end } = getStartAndEnd({ page, rowsPerPage, count });
  const { isPhone, isTablet } = useDevice();
  return (
    <Box sx={{ containerType: 'inline-size' }}>
      <Box display={'flex'} justifyContent={'space-between'} py={2} alignItems={'center'} gap={2}>
        {isPhone || isTablet ? (
          <TwoLinedPagination
            start={start}
            end={end}
            count={count}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            onPageChange={onPageChange}
            siblingCount={siblingCount}
          />
        ) : (
          <OneLinedPagination
            start={start}
            end={end}
            count={count}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            onPageChange={onPageChange}
            siblingCount={siblingCount}
          />
        )}
      </Box>
    </Box>
  );
};

const getStartAndEnd = ({ page, rowsPerPage, count }: { page: number; rowsPerPage: number; count: number }) => {
  if (count === 0) return { start: 0, end: 0 };

  const start = page * rowsPerPage + 1;
  const end = Math.min((page + 1) * rowsPerPage, count);

  return { start, end };
};
