import { FC } from 'react';
import { PaginationProps } from '@fjuel/components/table/TablePagination/PaginationProps.ts';
import { DisplayRange } from '@fjuel/components/table/TablePagination/DisplayRange.tsx';
import { RowsPerPageSelector } from '@fjuel/components/table/TablePagination/RowsPerPageSelector.tsx';
import { Paginator } from '@fjuel/components/table/TablePagination/Paginator.tsx';

export const OneLinedPagination: FC<PaginationProps> = ({
  start,
  end,
  count,
  page,
  rowsPerPage,
  onRowsPerPageChange,
  onPageChange,
  siblingCount,
}) => {
  return (
    <>
      <DisplayRange start={start} end={end} count={count} />
      <Paginator
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        siblingCount={siblingCount}
      />
      <RowsPerPageSelector rowsPerPage={rowsPerPage} onRowsPerPageChange={onRowsPerPageChange} />
    </>
  );
};
