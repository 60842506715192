import { fjuelApi } from '@fjuel/configs/api';
import { VesselSchema } from '@fjuel/schemas/VesselSchema';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

const getVessels = async () => z.array(VesselSchema).parse(await fjuelApi.get('vessel').json());

export const useVessels = createQuery({
  primaryKey: 'vessels',
  queryFn: getVessels,
  retry: false,
});
