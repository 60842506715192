import React, { FC, MouseEventHandler } from 'react';
import { Button, ButtonProps, ListItem, ListItemText, styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface MenuTopLevelItemProps {
  onClick?: MouseEventHandler<HTMLLIElement>;
  icon: ButtonProps['startIcon'];
  isOpen?: boolean;
  children?: React.ReactNode;
}

export const MenuTopLevelItem: FC<MenuTopLevelItemProps> = ({ onClick, icon, isOpen, children }) => {
  return (
    <ListItem onClick={onClick} disableGutters disablePadding>
      <CustomButton aria-expanded={isOpen} size="small" variant="text" fullWidth startIcon={icon}>
        <ListItemText>{children}</ListItemText>
        <ChevronIcon isOpen={isOpen} />
      </CustomButton>
    </ListItem>
  );
};

const CustomButton = styled(Button)(() => ({
  maxWidth: '100%',
  alignItems: 'baseline',
  color: 'inherit',
  overflowX: 'hidden',
  borderColor: 'inherit',
  textAlign: 'start',
  textTransform: 'none',
  '&:hover': {
    minWidth: 'fit-content',
  },
  '& .MuiTypography-root': {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
}));

const ChevronIcon: FC<{ isOpen?: boolean }> = ({ isOpen }) => {
  return (
    isOpen !== undefined &&
    (isOpen ? (
      <ExpandLess sx={{ transform: 'translateY(4px)' }} />
    ) : (
      <ExpandMore sx={{ transform: 'translateY(4px)' }} />
    ))
  );
};
