import { FC } from 'react';
import { ContainerPopover } from '@fjuel/components/popovers/ContainerPopover';
import { ActiveSessionSchema } from '@fjuel/schemas';
import { VesselPopover } from '@fjuel/components/popovers/VesselPopover.tsx';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { PopupDetails } from './PopupDetails.ts';
import { StyledPopover } from './StyledPopover.tsx';

interface PopOverProps {
  popOverDetails: PopupDetails;
  setPopOverDetails: (value: PopupDetails | null) => void;
}

export const PopOver: FC<PopOverProps> = ({ popOverDetails, setPopOverDetails }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <StyledPopover
      maxWidth="450px"
      longitude={popOverDetails.lng}
      latitude={popOverDetails.lat}
      closeOnClick={false}
      closeOnMove={false}
      closeButton={false}
      onClose={() => {
        setPopOverDetails(null);
      }}
    >
      {popOverDetails.type === 'shorePowerUnit' ? (
        <ContainerPopover
          unit={popOverDetails}
          onCloseClicked={() => setPopOverDetails(null)}
          onSeeDetailsClicked={(spu) => {
            const session =
              typeof spu.session === 'string' ? ActiveSessionSchema.parse(JSON.parse(spu.session)) : spu.session;
            return session?.id && navigate(`session/${session.id}`);
          }}
        />
      ) : popOverDetails.type === 'vessel' ? (
        <VesselPopover
          vessel={popOverDetails}
          onSeeDetailsClicked={(vessel) => navigate(`vessel/${vessel.id}`)}
          onCloseClicked={() => setPopOverDetails(null)}
        />
      ) : (
        <Box display={'flex'} gap={1} whiteSpace={'nowrap'} alignItems={'center'}>
          <Typography whiteSpace={'normal'} fontWeight={600} fontSize={22}>
            {popOverDetails.name}
          </Typography>
          <IconButton onClick={() => setPopOverDetails(null)} size="small">
            <X color={palette.primary.main} />
          </IconButton>
        </Box>
      )}
    </StyledPopover>
  );
};
