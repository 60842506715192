import { fjuelApi } from '@fjuel/configs/api';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';
import { minutesToMilliseconds } from 'date-fns';
import { HarbourVessel, HarbourVesselSchema } from '../../schemas';

type GetShorePowerUnitVesselsQueryParams = {
  shorePowerUnitId: string | undefined;
  from: Date;
  to: Date;
};

export const useShorePowerUnitVessels = createQuery<HarbourVessel[], GetShorePowerUnitVesselsQueryParams>({
  primaryKey: 'shorepowerunit-vessels',
  queryFn: ({ queryKey: [_, variables] }) => getShorePowerUnitVessels(variables),
  retry: false,
  staleTime: minutesToMilliseconds(30),
  gcTime: minutesToMilliseconds(60),
  use: [disableIfVariablesUndefined('shorePowerUnitId')],
});

export const getShorePowerUnitVessels = async ({
  shorePowerUnitId,
  from,
  to,
}: GetShorePowerUnitVesselsQueryParams): Promise<HarbourVessel[]> => {
  if (!shorePowerUnitId || !from || !to) return [];
  const searchParams = new URLSearchParams();
  searchParams.append('from', from.toJSON());
  searchParams.append('to', to.toJSON());

  return z.array(HarbourVesselSchema).parse(
    await fjuelApi
      .get(`shorepowerunit/${shorePowerUnitId}/vessels`, {
        searchParams,
      })
      .json()
  );
};
