import { Chip, ChipProps, Stack } from '@mui/material';
import { Circle, MinusCircle, PlusCircle } from '@phosphor-icons/react';
import { Column, RowData } from '@tanstack/react-table';
import { ReactElement } from 'react';

type FilterStateChipProps<TMap extends Map<string, FilterState>> = {
  alternative: string;
  filterMap: TMap;
} & (
  | {
      onClick: ChipProps['onClick'];
      onDelete?: never;
    }
  | {
      onDelete: ChipProps['onDelete'];
      deleteIcon?: ChipProps['deleteIcon'];
      onClick?: never;
    }
);

export const FilterStateChip = <TMap extends Map<string, FilterState>>({
  alternative,
  filterMap,
  ...rest
}: FilterStateChipProps<TMap>) => (
  <Chip
    label={alternative}
    color={getFilterStateColor(filterMap.get(alternative))}
    icon={getChipIcon(filterMap.get(alternative))}
    variant="outlined"
    size="small"
    {...rest}
  />
);
export type FilterState = 'ignore' | 'include' | 'exclude';

export const IncludedFilter = <TData extends RowData>({
  column,
  options,
}: {
  column: Column<TData>;
  options: string[];
}) => {
  const filterMap = (column.getFilterValue() as Map<string, FilterState>) ?? new Map();
  return (
    <Stack direction={'row'} gap={1}>
      {options.map((alternative) => (
        <FilterStateChip
          key={alternative}
          alternative={alternative}
          onClick={(e) => {
            e.stopPropagation();
            column.setFilterValue(filterMap.set(alternative, cycleFilterState(filterMap.get(alternative))));
          }}
          filterMap={filterMap}
        />
      ))}
    </Stack>
  );
};
const getChipIcon = (value: FilterState | undefined): ReactElement => {
  switch (value) {
    case 'exclude':
      return <MinusCircle />;
    case 'include':
      return <PlusCircle />;
    case 'ignore':
    default:
      return <Circle />;
  }
};
const cycleFilterState = (oldState: FilterState | undefined): FilterState => {
  switch (oldState) {
    case 'exclude':
      return 'ignore';
    case 'include':
      return 'exclude';
    default:
      return 'include';
  }
};
const getFilterStateColor = (state: FilterState | undefined) => {
  switch (state) {
    case 'exclude':
      return 'secondary';
    case 'include':
      return 'primary';
    default:
      return 'info';
  }
};
