import { createTheme } from '@mui/material';
import { XCircle } from '@phosphor-icons/react';

export const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            backgroundColor: 'hsla(224, 64%, 16%, 1)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: 'hsla(182, 44%, 33%, 1)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: 'inherit',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: 'inherit',
            width: '.8ex',
            height: '.8ex',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: 'currentColor',
            minHeight: 24,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input.Mui-disabled, & .MuiInputLabel-root.Mui-disabled': {
            WebkitTextFillColor: 'hsla(0, 0%, 100%, 0.4)',
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': { borderColor: '#FFF' },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px hsla(182, 44%, 10%, .8) inset',
            WebkitTextFillColor: '#fff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#fff',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <XCircle />,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'hsla(333, 75%, 60%, 1)',
          color: 'hsla(333, 45%, 14%, 1)',
          '.MuiTooltip-arrow': {
            color: 'hsla(333, 75%, 60%, 1)',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#8BFCB6',
      contrastText: '#2a4c37',
    },
    secondary: {
      main: 'hsla(333, 75%, 60%, 1)',
      contrastText: 'hsla(333, 75%, 40%, 1)',
    },
    success: {
      main: '#8BFCB6',
      contrastText: '#2a4c37',
    },
    background: {
      default: 'unset',
      paper: '#0F1D45',
    },
    text: {
      primary: '#fff',
      secondary: 'hsla(0, 0%, 60%, 1)',
      disabled: 'hsla(0, 0%, 100%, 0.4)',
    },
    action: {
      disabled: 'hsla(0, 0%, 100%, 0.4)',
      disabledBackground: 'hsla(0, 0%, 100%, 0.52)',
      hover: 'hsla(0, 0%, 100%, 0.20)',
      focus: 'hsla(0, 0%, 100%, 0.12)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
});
