import { fjuelApi } from '@fjuel/configs/api';
import { Me, MeSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';

export const useMe = createQuery<Me, { isLoggedIn: boolean }>({
  primaryKey: 'me',
  queryFn: async () => {
    return MeSchema.parse(await fjuelApi.get('User/me').json());
  },
});
