import { fjuelApi } from '@fjuel/configs/api';
import { KPI, KPIResponseSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useCompanyKpiForPeriod = createQuery<
  KPI,
  { companyId: string | undefined; period: 'monthly' | 'yearly' | 'daily' }
>({
  primaryKey: 'companyKpi',
  queryFn: async ({ queryKey: [, { companyId, period }] }) =>
    KPIResponseSchema.parse(await fjuelApi.get(`company/${companyId}/kpi/${period}`).json()),
  use: [disableIfVariablesUndefined('companyId')],
});

export type useCompanyKpiResult =
  | {
      isPending: false;
      error: string;
      yearly: undefined;
      monthly: undefined;
      daily: undefined;
    }
  | {
      isPending: false;
      error: undefined;
      yearly: KPI;
      monthly: KPI;
      daily: KPI;
    }
  | {
      isPending: true;
      error: undefined;
      yearly: undefined;
      monthly: undefined;
      daily: undefined;
    };

export const useCompanyKpi = (companyId: string | undefined): useCompanyKpiResult => {
  const {
    isError: yearlyError,
    data: yearly,
    isPending: isLoadingYearly,
  } = useCompanyKpiForPeriod({
    variables: { companyId, period: 'yearly' },
  });
  const {
    isError: monthlyError,
    data: monthly,
    isPending: isLoadingMonthly,
  } = useCompanyKpiForPeriod({
    variables: { companyId, period: 'monthly' },
  });
  const {
    isError: dailyError,
    data: daily,
    isPending: isLoadingDaily,
  } = useCompanyKpiForPeriod({ variables: { companyId, period: 'daily' } });

  if (isLoadingYearly || isLoadingMonthly || isLoadingDaily) {
    return {
      isPending: true,
      error: undefined,
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    };
  }

  if (yearlyError || monthlyError || dailyError) {
    return {
      isPending: false,
      error: 'Error fetching company KPI',
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    };
  }

  return {
    isPending: false,
    error: undefined,
    yearly,
    monthly,
    daily,
  };
};
