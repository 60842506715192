import { fjuelApi } from '@fjuel/configs/api';
import { IMONumberStrict, VesselByImo, VesselByImoSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { enableIf } from './middlewares/enableIf';

export const useVesselByImo = createQuery<VesselByImo, { imo?: string }>({
  primaryKey: 'vesselByImo',
  queryFn: async ({ queryKey: [, { imo }] }) => {
    return VesselByImoSchema.parse(await fjuelApi.get(`vessel/imo/${imo}`).json());
  },
  use: [enableIf(({ imo }) => !!imo && imo.length === 7 && IMONumberStrict.safeParse(imo).success)],
});
