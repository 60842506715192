import { fjuelApi } from '@fjuel/configs/api';
import { createQuery } from 'react-query-kit';
import { IPCWithShorePowerUnit, IPCWithShorePowerUnitSchema } from '@fjuel/schemas';

export const useIPC = createQuery<IPCWithShorePowerUnit, { ipcId: string | undefined }>({
  primaryKey: 'ipcs-active-session',
  queryFn: async ({ queryKey: [, { ipcId }] }) => {
    const json = await fjuelApi.get(`ipc/${ipcId}`).json();
    return IPCWithShorePowerUnitSchema.parse(json);
  },
});
