export const SourceLayers = {
  shorePowerUnits: 'shorePowerUnits',
  shorePowerUnitsNotCharging: 'shorePowerUnitsNotCharging',
  vessels: 'vessels',
  ipcs: 'ipcs',
};

export const layout = ({ showLabels }: { showLabels: boolean }) =>
  ({
    'text-field': ['get', 'name'],
    'text-font': ['Inter Regular'],
    'text-size': showLabels ? 12 : 0,
    'text-anchor': 'left',
    'text-offset': [1.5, 0],
    'icon-allow-overlap': true,
  }) satisfies mapboxgl.SymbolLayout;

export const paint = {
  'icon-halo-width': 1,
  'text-color': 'hsla(143, 95%, 100%, 1)',
} satisfies mapboxgl.SymbolPaint;
