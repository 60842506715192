import { Alert, Stack, TableCell, TableRow } from '@mui/material';
import { RowData, Table as TableType } from '@tanstack/react-table';

export const EmptyTable = <TData extends RowData>({
  table,
  label = 'No data',
}: {
  table: TableType<TData>;
  label?: string;
}) => (
  <TableRow>
    <TableCell colSpan={table.getVisibleFlatColumns().length} sx={{ px: 0.5, py: 1 }}>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="info">{label}</Alert>
      </Stack>
    </TableCell>
  </TableRow>
);
