import React from 'react';
import { useDevice } from '../Device/DeviceContext.ts';
import { DesktopLayout } from '@fjuel/pages/Layout/DesktopLayout.tsx';
import { TabletLayout } from '@fjuel/pages/Layout/TabletLayout.tsx';
import { PhoneLayout } from './PhoneLayout.tsx';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isPhone, isTablet } = useDevice();

  if (isPhone) {
    return <PhoneLayout>{children}</PhoneLayout>;
  } else if (isTablet) {
    return <TabletLayout>{children}</TabletLayout>;
  } else {
    return <DesktopLayout>{children}</DesktopLayout>;
  }
};
