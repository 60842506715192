/* eslint-disable max-lines */
import { useApplicationContext } from '@fjuel/ApplicationContext';
import { env } from '@fjuel/configs/env';
import { useSiteIpcs } from '@fjuel/hooks';
import { ShorePowerUnitActiveSession } from '@fjuel/pages/shorePowerUnits/ActiveSessionTable';
import { ActiveSessionSchema, HarbourVessel, IPC, ShorePowerUnit } from '@fjuel/schemas';
import { ShorePowerUnitToFeature } from '@fjuel/shorePowerUnitToFeature';
import { Box, IconButton, styled } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Layer, Map as MapGL, MapProps, MapRef, Popup, Source, ViewState } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { Coordinates } from './Coordinates';
import { TextWithIcon } from './TextWithIcon';
import { ContainerPopover, ContainerPopoverProps } from './popovers/ContainerPopover';
import { VesselPopover } from './popovers/VesselPopover';

const ipcToFeature = (ipc: IPC): GeoJSON.Feature<GeoJSON.Point, IPC> => ({
  type: 'Feature',
  properties: { ...ipc },
  geometry: {
    type: 'Point',
    coordinates: [ipc.location.lng, ipc.location.lat],
  },
});

type PopupDetails = Coordinates &
  (
    | (ContainerPopoverProps['unit'] & { type: 'shorePowerUnit' })
    | (Pick<HarbourVessel, 'id' | 'name' | 'status' | 'imo' | 'voltageV' | 'frequencyHz'> & { type: 'vessel' })
    | (IPC & { type: 'ipc' })
  );
export type ShorePowerUnitMapProps = {
  onMove?: MapProps['onMove'];
  shorePowerUnit: ShorePowerUnit;
  showLabels?: boolean;
  viewState: Omit<ViewState, 'padding'>;
} & Pick<MapProps, 'style'>;

export type ShorePowerUnitMapRef = {
  resize?: VoidFunction;
};

export const ShorePowerUnitMap = forwardRef<ShorePowerUnitMapRef, ShorePowerUnitMapProps>(function ShorePowerUnitMap(
  { onMove, viewState, style, showLabels = false, shorePowerUnit },
  ref
) {
  const { siteId } = useApplicationContext();
  const navigate = useNavigate();

  const { data: ipcs } = useSiteIpcs({ variables: { siteId }, select: (ipcs) => ipcs.map(ipcToFeature) });

  const shorePowerUnits = [ShorePowerUnitToFeature(shorePowerUnit)];

  const mapRef = useRef<MapRef | null>(null);
  useImperativeHandle(
    ref,
    () => ({
      resize: () => mapRef.current?.resize(),
    }),
    []
  );

  useEffect(() => {
    const map = mapRef.current;
    return () => {
      imagesToLoad.forEach(({ id }) => {
        if (!map) return;
        map?.removeImage?.(id);
      });
    };
  }, []);

  const [popOverDetails, setPopOverDetails] = useState<PopupDetails | null>(null);

  const handleOnClick: MapProps['onClick'] = (e) => {
    if (!e.features) return;
    const feature = e.features[0];
    if (!feature) return;
    if (!feature.properties) return;

    switch (feature.layer.id as keyof typeof SourceLayers) {
      case 'ipcs':
        return setPopOverDetails({
          type: 'ipc',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          ...(feature.properties as IPC),
        });
      case 'shorePowerUnits':
      case 'shorePowerUnitsNotCharging':
        return setPopOverDetails({
          type: 'shorePowerUnit',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          ...(feature.properties as ShorePowerUnitActiveSession),
        });
      case 'vessels':
        return setPopOverDetails({
          type: 'vessel',
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          id: feature.properties.id as HarbourVessel['id'],
          name: feature.properties.name as string,
          status: feature.properties.status as HarbourVessel['status'],
          imo: feature.properties.imo as HarbourVessel['imo'],
          frequencyHz: feature.properties.frequencyHz as HarbourVessel['frequencyHz'],
          voltageV: feature.properties.voltageV as HarbourVessel['voltageV'],
        });
    }
  };

  return (
    <Box
      component="div"
      sx={{
        '& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
          borderColor: 'transparent',
          borderLeftColor: '#0f1d45',
        },
        '& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
          borderColor: 'transparent',
          borderRightColor: '#0f1d45',
        },
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setPopOverDetails(null);
        }
      }}
    >
      <MapGL
        onLoad={(e) => {
          const map = e.target;
          if (!map) return;
          imagesToLoad.forEach(({ src, id }) => {
            map?.loadImage(src, (error, image) => {
              if (error) throw error;
              if (!map) return;
              if (!image) return;
              if (map.hasImage(id)) return;
              map.addImage(id, image);
            });
          });
        }}
        style={{ ...style }}
        key={siteId}
        attributionControl={false}
        logoPosition="top-right"
        ref={mapRef}
        {...viewState}
        interactiveLayerIds={['area', ...Object.keys(SourceLayers)]}
        mapboxAccessToken={env.VITE_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/steffenholanger/clje9twhn005201peehih699c"
        initialViewState={{
          latitude: 67.28350893,
          longitude: 14.37456511,
          zoom: 15,
        }}
        onMouseEnter={() => {
          if (mapRef.current) {
            mapRef.current.getCanvas().style.cursor = 'pointer';
          }
        }}
        onMouseLeave={() => {
          if (mapRef.current) {
            mapRef.current.getCanvas().style.cursor = '';
          }
        }}
        onClick={handleOnClick}
        onMove={onMove}
      >
        <Layer
          id="3d-buildings"
          source="composite"
          source-layer="building"
          filter={['==', 'extrude', 'true']}
          type="fill-extrusion"
          paint={{
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6,
          }}
        />
        <Source id={SourceLayers.ipcs} data={{ type: 'FeatureCollection', features: ipcs ?? [] }} type="geojson">
          <Layer
            id={SourceLayers.ipcs}
            type="symbol"
            paint={paint}
            layout={{ ...layout({ showLabels }), 'icon-size': 0.7, 'icon-image': 'IPC' }}
          />
        </Source>
        <Source
          id="ShorePowerUnits"
          data={{ type: 'FeatureCollection', features: shorePowerUnits ?? [] }}
          type="geojson"
        >
          <Layer
            id="area"
            type="fill-extrusion"
            paint={{
              'fill-extrusion-color': ['get', 'color'],
              'fill-extrusion-opacity': 0.8,
              'fill-extrusion-height': ['get', 'height'],
              'fill-extrusion-base': ['get', 'base_height'],
            }}
          />
        </Source>

        <Source
          id="shorePowerUnits"
          data={{
            type: 'FeatureCollection',
            features: shorePowerUnits,
          }}
          type="geojson"
        >
          <Layer
            id={SourceLayers.shorePowerUnits}
            type="symbol"
            paint={paint}
            layout={{ ...layout({ showLabels }), 'icon-size': 0.2, 'icon-image': 'shore-power-unit' }}
          />
        </Source>
        {popOverDetails && (
          <StyledPopover
            maxWidth="650px"
            longitude={popOverDetails.lng}
            latitude={popOverDetails.lat}
            closeOnClick={false}
            closeOnMove={false}
            closeButton={false}
            onClose={() => {
              setPopOverDetails(null);
            }}
          >
            {popOverDetails.type === 'shorePowerUnit' ? (
              <ContainerPopover
                unit={popOverDetails}
                onCloseClicked={() => setPopOverDetails(null)}
                onSeeDetailsClicked={(spu) => {
                  const session =
                    typeof spu.session === 'string' ? ActiveSessionSchema.parse(JSON.parse(spu.session)) : spu.session;
                  return session?.id && navigate(`session/${session.id}`);
                }}
              />
            ) : popOverDetails.type === 'vessel' ? (
              <VesselPopover
                vessel={popOverDetails}
                onSeeDetailsClicked={(vessel) => navigate(`vessel/${vessel.id}`)}
                onCloseClicked={() => setPopOverDetails(null)}
              />
            ) : (
              <Box>
                <TextWithIcon variant={'h6'}>{popOverDetails.name}</TextWithIcon>

                <IconButton onClick={() => setPopOverDetails(null)}>
                  <X />
                </IconButton>
              </Box>
            )}
          </StyledPopover>
        )}
      </MapGL>
    </Box>
  );
});

const imagesToLoad: readonly { src: string; id: string }[] = [
  {
    src: '/IPC.png',
    id: 'IPC',
  },
  {
    src: '/ShorePowerUnit.png',
    id: 'shore-power-unit',
  },
  {
    src: '/ShorePowerUnit-not-charging.png',
    id: 'shore-power-unit-not-charging',
  },
] as const;

const SourceLayers = {
  shorePowerUnits: 'shorePowerUnits',
  shorePowerUnitsNotCharging: 'shorePowerUnitsNotCharging',
  vessels: 'vessels',
  ipcs: 'ipcs',
} as const;

const StyledPopover = styled(Popup)(({ theme }) => ({
  '.mapboxgl-popup-content': {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    backgroundColor: '#0f1d45',
    color: 'white',
  },
  '.mapboxgl-popup-tip': {
    borderTopColor: '#0f1d45',
    borderBottomColor: '#0f1d45',
  },
  '& .mapboxgl-popup-close-button': {
    color: 'white',
  },
}));

const layout = ({ showLabels }: { showLabels: boolean }) =>
  ({
    'text-field': ['get', 'name'],
    'text-font': ['Inter Regular'],
    'text-size': showLabels ? 12 : 0,
    'text-anchor': 'left',
    'text-offset': [1.5, 0],
    'icon-allow-overlap': true,
  }) satisfies mapboxgl.SymbolLayout;

const paint = {
  'icon-halo-width': 1,
  'text-color': 'hsla(143, 95%, 100%, 1)',
} satisfies mapboxgl.SymbolPaint;
