import { Box, styled } from '@mui/material';
import { FC } from 'react';

interface OverlayProps {
  isActive: boolean;
  onClick: () => void;
}

const StyledOverlay = styled(Box)<OverlayProps>(({ isActive }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  transition: 'width 0.3s ease',
  height: '100vh',
  backgroundColor: 'hsla(256, 83%, 9%, 0.6)',
  zIndex: 1200,
  display: isActive ? 'block' : 'none',
}));

export const Overlay: FC<OverlayProps> = ({ isActive, onClick }) => {
  return <StyledOverlay isActive={isActive} onClick={onClick} sx={{ cursor: 'pointer' }} />;
};
