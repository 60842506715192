import { fjuelApi } from '@fjuel/configs/api';
import { EffectMarkup, EffectMarkupSchema } from '@fjuel/schemas';
import { Must } from '@fjuel/utils/utilityTypes';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

export const useCreateEffectMarkup = createMutation({
  mutationFn: async ({ effectMarkup }: { effectMarkup: EffectMarkup }) => {
    const body = EffectMarkupSchema.omit({ id: true })
      .superRefine((v, ctx): v is Must<Omit<EffectMarkup, 'id'>> => {
        if (v.priceMarkupId === null) {
          ctx.addIssue({
            path: ['priceMarkupId'],
            code: z.ZodIssueCode.invalid_type,
            fatal: true,
            expected: 'string',
            received: typeof v.priceMarkupId,
            message: 'priceMarkupId is required',
          });
        }
        return z.NEVER;
      })
      .parse(effectMarkup);

    return await fjuelApi.post(`priceMarkup/${body.priceMarkupId}/addEffectMarkup`, {
      json: EffectMarkupSchema.parse(body),
    });
  },
});
