import { z } from 'zod';

export const SessionPricesDataPointSchema = z.object({
  timestamp: z.coerce.date().describe('Timestamp of the data point'),
  energyConsumed: z.number().describe('Energy consumed at the time of the data point'),
  spotPrice: z.coerce.number().describe('Spot price at the time of the data point'),
  markup: z.coerce.number().describe('Markup at the time of the data point'),
  unitPrice: z.coerce.number().describe('Unit price at the time of the data point'),
  price: z.coerce.number().describe('Total cost of the energy consumed at the time of the data point'),
  isOfficialSpotPrice: z.boolean().describe('Whether the spot price is official or not'),
});

export type SessionPricesDataPoint = z.infer<typeof SessionPricesDataPointSchema>;

export const SessionPricesSchema = z
  .array(SessionPricesDataPointSchema)
  .describe('A list of price data points from the session');
export type SessionPrices = z.infer<typeof SessionPricesSchema>;
