import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { SourceLayers, layout, paint } from '@fjuel/components/SiteMap/Constants.ts';
import { useSiteAreaVessels } from '@fjuel/hooks';
import { vesselToFeature } from '@fjuel/mapbox-transformers/vesselToFeature.ts';
import { LayerProps } from './LayerProps.ts';

export const VesselsLayer: FC<LayerProps> = ({ siteId, showLabels }) => {
  const { data: vessels } = useSiteAreaVessels({
    variables: { siteId },
    select: (vessels) => vessels.map(vesselToFeature),
  });

  return (
    <Source id={SourceLayers.vessels} data={{ type: 'FeatureCollection', features: vessels ?? [] }} type="geojson">
      <Layer
        id={SourceLayers.vessels}
        type="symbol"
        paint={paint}
        layout={{ ...layout({ showLabels }), 'icon-size': 0.5, 'icon-image': 'small-boat' }}
      />
    </Source>
  );
};
