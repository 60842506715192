import { z } from 'zod';

export const UserFormSchema = z.object({
  id: z.string().uuid().nullable().default(null),
  givenName: z.string().nonempty(),
  surname: z.string().nonempty(),
  email: z.string().email(),
  sites: z.array(z.string().uuid()).default([]),
  roles: z.array(z.string().uuid()).default([]),
  companyId: z.string().uuid().or(z.literal('00000000-0000-0000-0000-000000000000')).nullable().default(null),
});

export type User = z.infer<typeof UserFormSchema>;
