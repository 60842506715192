import { fjuelApi } from '@fjuel/configs/api';
import { PriceMarkupResponseSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSitePriceMarkups = createQuery<
  z.infer<typeof PriceMarkupResponseSchema>,
  { siteId: string | undefined }
>({
  primaryKey: 'site-price-markups',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    PriceMarkupResponseSchema.parse(await fjuelApi.get(`site/${siteId}/pricemarkups`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
});
